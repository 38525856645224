<template>
    <div class="modal-content">
        <form novalidate @submit.prevent="login">
            <ErrorMessage v-if="errorMessage" :error-message="errorMessage"></ErrorMessage>
            <div class="form-group">
                <input
                        class="form-control form-control-dark"
                        :class="{'is-invalid': $v.email.$error}"
                        type="email"
                        placeholder="E-mail*"
                        v-model.trim="email"
                        @keydown.space.prevent
                        ref="emailInput"
                >
                <div
                        v-if="$v.email.$dirty && !$v.email.required"
                        class="invalid-feedback"
                >
                    Поле обязательно для заполнения
                </div>
                <div
                        v-else-if="$v.email.$dirty && !$v.email.email"
                        class="invalid-feedback"
                >
                    Некорректный E-mail
                </div>
            </div>
            <div class="form-group form-group-password">
                <input
                        class="form-control form-control-dark"
                        :class="{'is-invalid': $v.password.$error}"
                        :type="passwordFieldType"
                        placeholder="Пароль*"
                        v-model.trim="password"
                        @keydown.space.prevent
                >
                <button
                        class="toggle-password-btn"
                        type="button"
                        @click="togglePasswordVisibility"
                >
                    <span v-if="passwordFieldType === 'password'" key="ico-visibility-off"><ico name="icoVisibilityOff"
                                                                                                color="#79919C"></ico></span>
                    <span v-else key="ico-visibility"><ico name="icoVisibility" color="#ffffff"></ico></span>
                </button>
                <div
                        v-if="$v.password.$dirty && !$v.password.required"
                        class="invalid-feedback"
                >
                    Поле обязательно для заполнения
                </div>
                <div
                        v-else-if="$v.password.$dirty && !$v.password.minLength"
                        class="invalid-feedback"
                >
                    Пароль должен содержать не менее {{$v.password.$params.minLength.min}} символов
                </div>
                <div class="forgot-password-wrapper">
                    <button
                            class="forgot-password"
                            type="button"
                            @click="openRecoveryPasswordModal"
                    >
                        Забыли пароль?
                    </button>
                </div>
            </div>
            <div class="form-footer">
                <button
                        class="btn btn-success"
                        type="submit"
                        :disabled="isDisabled"
                        :title="isDisabled ? 'Заполните обязательные поля' : ''"
                >
                    Войти
                </button>
                <button
                        class="btn btn-transparent"
                        type="button"
                        @click="openRegistrationModal"
                >
                    Зарегистрироваться
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import {email, minLength, required} from 'vuelidate/lib/validators'
    import RegistrationModal from '@/components/homePage/RegistrationModal'
    import {mapActions} from "vuex"
    import {purchasesPath} from "@/router/purchases"
    import {RecoveryPasswordModalProperties, RegistrationModalProperties} from '@/utils/modalsProperties'
    import ErrorMessage from '@/components/common/ErrorMessage'
    import RecoveryPasswordModal from "@/components/homePage/RecoveryPasswordModal"
    import {connectWS} from "@/utils/stompClient"
    import {serviceUnavailable} from "@/utils/constants/errorMessages"

    export default {
        name: "LoginModal",
        data() {
            return {
                email: '',
                password: '',
                passwordFieldType: 'password',
                errorMessage: null,
            }
        },
        components: {
            ErrorMessage
        },
        validations: {
            email: {required, email},
            password: {required, minLength: minLength(8)}
        },
        computed: {
            isDisabled: function () {
                return !this.email || !this.password
            },
        },
        methods: {
            ...mapActions('auth', ['loginUser']),
            togglePasswordVisibility() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
            },
            async login() {
                if (this.$v.$invalid) {
                    this.$v.$touch()
                    return
                }

                const credentials = {
                    email: this.email,
                    password: this.password
                }

                try {
                    await this.loginUser(credentials)
                    await this.$router.push(purchasesPath)
                    connectWS()
                    this.$modal.container.destroyModal()
                } catch (e) {
                    if (e.status) {
                        this.$toast.error(serviceUnavailable + "(2)")
                        return
                    }
                    this.errorMessage = e.message
                    if (e.cleanPassword) {
                        this.password = null
                    }
                }
            },
            openRegistrationModal() {
                this.$modal.show(
                    RegistrationModal,
                    null,
                    RegistrationModalProperties
                )
            },
            openRecoveryPasswordModal() {
                this.$modal.show(
                    RecoveryPasswordModal,
                    null,
                    RecoveryPasswordModalProperties
                )
            },
        },
        mounted() {
            this.$refs['emailInput'].focus()
        }
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .form-group {
        margin-bottom: 10px;
    }

    .forgot-password-wrapper {
        text-align: right;
    }

    .forgot-password {
        font-size: 12px;
        line-height: 2;
        letter-spacing: 0.2px;
        color: @text-color-light;

        &:hover {
            text-decoration: underline;
        }
    }
</style>
