<template>
    <div class="modal-content">
        <div class="ico-wrapper">
            <ico name="icoCheckCircle" color="#00BA82" size="72"></ico>
        </div>
        <p v-html="componentProps.text"></p>
    </div>
</template>

<script>
export default {
    name: "CheckEmailModal",
    props: ['componentProps'],
}
</script>

<style lang="less" scoped>
    .ico-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 16px;
    }
    p {
        line-height: 1.5;
        text-align: center;
        color: #ffffff;
    }
</style>
