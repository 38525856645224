<template>
    <div class="modal-content">
        <form novalidate @submit.prevent="recoveryPassword">
            <ErrorMessage v-if="errorMessage" :error-message="errorMessage"></ErrorMessage>
            <p class="modal-text">Введите Email, который вы использовали при&nbsp;регистрации. На&nbsp;данный Email мы
                вышлем
                новый пароль.</p>
            <div class="form-group">
                <input
                        class="form-control form-control-dark"
                        :class="{'is-invalid': $v.email.$error}"
                        type="email"
                        placeholder="E-mail*"
                        v-model.trim="email"
                        @keydown.space.prevent
                >
                <div
                        v-if="$v.email.$dirty && !$v.email.required"
                        class="invalid-feedback"
                >
                    Поле обязательно для заполнения
                </div>
                <div
                        v-else-if="$v.email.$dirty && !$v.email.email"
                        class="invalid-feedback"
                >
                    Некорректный E-mail
                </div>
            </div>
            <div class="form-footer">
                <button
                        class="btn btn-success"
                        type="submit"
                        :disabled="isEmptyEmail || isRequesting"
                        :title="isEmptyEmail ? 'Заполните обязательные поля' : ''"
                >
                    Восстановить пароль
                </button>
            </div>
        </form>
    </div>
</template>
<script>
    import {email, required} from 'vuelidate/lib/validators'
    import ErrorMessage from "@/components/common/ErrorMessage"
    import api from "../../application"
    import CheckEmailModal from "@/components/common/SuccessModal"
    import {SuccessModalProperties} from "@/utils/modalsProperties"

    export default {
        name: "RestorePasswordModal",
        data() {
            return {
                email: null,
                errorMessage: null,
                isRequesting: false,
            }
        },
        components: {
            ErrorMessage
        },
        computed: {
            isEmptyEmail: function () {
                return !this.email
            },
        },
        validations: {
            email: {required, email}
        },
        methods: {
            openSuccessModal() {
                this.$modal.show(
                    CheckEmailModal,
                    {
                        text: 'Новый пароль был отправлен на&nbsp;Ваш Email. Пожалуйста, используйте его для&nbsp;входа в&nbsp;систему.'
                    },
                    SuccessModalProperties
                );
            },
            async recoveryPassword() {
                if (this.$v.$invalid) {
                    this.$v.$touch()
                    return
                }
                const formData = {
                    email: this.email
                }

                this.isRequesting = true
                try {
                    await api.auth.recoveryPassword(formData)
                    this.openSuccessModal()
                    this.isRequesting = false
                    this.email = null
                } catch (e) {
                    this.errorMessage = e.response.data.errorMessage
                    this.isRequesting = false
                }
            }
        }
    }
</script>
<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .modal-text {
        margin-bottom: 16px;
        color: @text-color-lighter;
    }
</style>