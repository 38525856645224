<template>
  <div class="modal-content">
    <form novalidate @submit.prevent="register">
      <ErrorMessage v-if="errorMessage" :error-message="errorMessage"></ErrorMessage>
      <div class="form-group">
        <input
            class="form-control form-control-dark"
            :class="{'is-invalid': $v.email.$error}"
            type="email"
            placeholder="E-mail*"
            v-model.trim="email"
            @keydown.space.prevent
            ref="emailInput"
        >
        <div
            v-if="$v.email.$dirty && !$v.email.required"
            class="invalid-feedback"
        >
          Поле обязательно для заполнения
        </div>
        <div
            v-else-if="$v.email.$dirty && !$v.email.email"
            class="invalid-feedback"
        >
          Некорректный E-mail
        </div>
      </div>
      <div class="form-group">
        <input
            class="form-control form-control-dark form-control-phone"
            :class="{'is-invalid': $v.phone.$error}"
            type="tel"
            placeholder="+ 375 (__) ___-__-__*"
            v-model="phone"
            v-mask="{mask: '+ 375 (99) 999-99-99', placeholder: '_', showMaskOnHover: false}"
        >
        <div
            v-if="$v.phone.$dirty && !$v.phone.required"
            class="invalid-feedback"
        >
          Поле обязательно для заполнения
        </div>
        <div
            v-if="$v.phone.$dirty && !$v.phone.validatePhone"
            class="invalid-feedback"
        >
          Некорректный номер телефона
        </div>
      </div>
      <div class="form-group form-group-password">
        <input
            class="form-control form-control-dark"
            :class="{'is-invalid': $v.password.$error}"
            :type="passwordFieldType"
            placeholder="Пароль*"
            v-model.trim="password"
            @keydown.space.prevent
        >
        <button
            class="toggle-password-btn"
            :class="{active: passwordFieldType === 'text'}"
            type="button"
            @click="togglePasswordVisibility"
            tabindex="-1"
        >
                    <span v-if="passwordFieldType === 'password'" key="ico-visibility-off">
                        <ico name="icoVisibilityOff" color="#79919C"/>
                    </span>
          <span v-else key="ico-visibility">
                        <ico name="icoVisibility" color="#ffffff"/>
                    </span>
        </button>
        <div
            v-if="$v.password.$dirty && !$v.password.required"
            class="invalid-feedback"
        >
          Поле обязательно для заполнения
        </div>
        <div
            v-else-if="$v.password.$dirty && !$v.password.minLength"
            class="invalid-feedback"
        >
          Пароль должен содержать не менее {{ $v.password.$params.minLength.min }} символов
        </div>
        <div
            v-if="$v.password.$dirty && !$v.password.strongPassword"
            class="invalid-feedback"
        >
          Пароль должен содержать минимум 1 цифру и одну заглавную букву
        </div>
      </div>
      <div class="form-group form-group-password">
        <input
            class="form-control form-control-dark"
            :class="{'is-invalid': $v.confirmPassword.$error}"
            :type="confirmPasswordFieldType"
            placeholder="Повторите пароль*"
            v-model.trim="confirmPassword"
            @keydown.space.prevent
        >
        <button
            class="toggle-password-btn"
            type="button"
            @click="toggleConfirmPasswordVisibility"
            tabindex="-1"
        >
                    <span v-if="confirmPasswordFieldType === 'password'" key="ico-visibility-off">
                        <ico name="icoVisibilityOff" color="#79919C"/>
                    </span>
          <span v-else key="ico-visibility">
                        <ico name="icoVisibility" color="#ffffff"/>
                    </span>
        </button>
        <div
            v-if="$v.confirmPassword.$dirty && !$v.confirmPassword.required"
            class="invalid-feedback"
        >
          Поле обязательно для заполнения
        </div>
        <div
            v-else-if="$v.confirmPassword.$dirty && !$v.confirmPassword.sameAsPassword"
            class="invalid-feedback"
        >
          Пароли не совпадают
        </div>
      </div>
      <div class="form-group-checkboxes">
        <input
            id="confidentiality"
            type="checkbox"
            v-model="confidentiality"
        >
        <label for="confidentiality">Принимаю <a :href="privacyPolicyUrl" target="_blank">Политику
          конфиденциальности</a></label>
        <input
            id="offer"
            type="checkbox"
            v-model="offer"
        >
        <label for="offer">Принимаю <a :href="termOfUseUrl" target="_blank">Правила пользования</a></label>
      </div>
      <div class="form-footer">
        <button
            class="btn btn-transparent"
            type="button"
            @click="openLoginModal"
        >
          Войти
        </button>
        <button
            class="btn btn-success"
            type="submit"
            :disabled="isDisabled"
            :title="isDisabled ? 'Заполните обязательные поля' : ''"
        >
          Зарегистрироваться
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {email, minLength, required, sameAs} from 'vuelidate/lib/validators'
import LoginModal from '@/components/homePage/LoginModal'
import SuccessModal from '@/components/common/SuccessModal'
import api from "../../application"
import {LoginModalProperties, SuccessModalProperties} from '@/utils/modalsProperties'
import {clearStorage} from "../../utils/auth"
import ErrorMessage from '@/components/common/ErrorMessage'
import Configuration from "@/configuration"

export default {
  name: "RegistrationModal",
  data() {
    return {
      email: null,
      phone: null,
      password: null,
      passwordFieldType: 'password',
      confirmPassword: null,
      confirmPasswordFieldType: 'password',
      confidentiality: false,
      offer: false,
      errorMessage: null,
    }
  },
  components: {
    ErrorMessage
  },
  validations: {
    email: {required, email},
    password: {
      required,
      minLength: minLength(8),
      strongPassword(password) {
        return /[A-ZА-Я]/.test(password) &&
            /[0-9]/.test(password);
      }
    },
    phone: {
      required,
      validatePhone: function (phone) {

        return /\+ 375 \(\d\d\) \d\d\d-\d\d-\d\d/.test(phone)
      }
    },
    confirmPassword: {required, sameAsPassword: sameAs('password')},
  },
  computed: {
    isDisabled: function () {
      return !this.email || !this.phone || !this.password || !this.confirmPassword || !this.confidentiality
          || !this.offer
    },
    termOfUseUrl() {
      return Configuration.value('apiEndpoint') + '/terms-of-use'
    },
    privacyPolicyUrl() {
      return Configuration.value('apiEndpoint') + '/privacy-policy'
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
    },
    openLoginModal() {
      this.$modal.show(
          LoginModal,
          null,
          LoginModalProperties
      );
    },
    openSuccessModal() {
      this.$modal.show(
          SuccessModal,
          {
            text: 'Пожалуйста, проверьте Ваш почтовый ящик для&nbsp;завершения регистрации'
          },
          SuccessModalProperties
      );
    },
    async register() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.errorText = null
      const formData = {
        email: this.email,
        phone: this.phone,
        password: this.password
      }

      try {
        await api.auth.registerUser(formData)
        clearStorage()
        this.openSuccessModal()
      } catch (e) {
        this.errorMessage = e.response.data.errorMessage
      }

    },
  },
  mounted() {
    this.$refs['emailInput'].focus()
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/less/variables.less';

.form-group {
  margin-bottom: 10px;
}

.form-control-phone {
  padding-left: 44px;
  background-repeat: no-repeat;
  background-position: 18px center;
  background-image: url("../../assets/img/ico-flag.svg");
  background-size: 18px auto;
}

.form-group-checkboxes {
  margin-top: 32px;
  margin-bottom: 16px;

  input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;

    &:checked + label::before {
      background-image: url("../../assets/img/ico-checkbox-checked.svg");
    }
  }

  label {
    position: relative;
    display: block;
    margin-bottom: 12px;
    padding-left: 30px;
    letter-spacing: 0.3px;
    color: #ffffff;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: -3px;
      top: -5px;
      z-index: 1;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px 24px;
      background-image: url("../../assets/img/ico-checkbox-unchecked.svg");
    }

    a {
      text-decoration: underline;
      color: @text-color-lighter;

      &:hover {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }
}
</style>
